<template>
  <div class="home flex flexC">
    <img alt="crm、erp、saas" class="w-full" src="../assets/pic1_1.png">
    <div class="tc mt20 pt20">
      <p class="fb f24 mb20 pt20">解决企业客户管理六大难题</p>
      <img alt="crm、erp、saas" src="../assets/pic1_2.png">
    </div>

    <div class="mt20 pt20 tc bgGray">
      <p class="fb f24 mb20 pt20">更多行业解决方案</p>
    </div>
    <div class="flex bgGray pl10 pb10 pr10">
      <div class="flex1 tc m20">
            <div>
              <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_3.png">
            </div>
            <div class="f12">ICT行业</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_4.png">
            <div class="f12">快速消费品</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_5.png">
            <div class="f12">装备制造</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_6.png">
            <div class="f12">Saas软件</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_7.png">
            <div class="f12">家居建材</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_8.png">
            <div class="f12">医疗健康</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_9.png">
            <div class="f12">教育培训</div>
      </div>
      <div class="flex1 tc m20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic1_10.png">
            <div class="f12">农牧农资</div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Crm',
  components: {
  },

}
</script>
